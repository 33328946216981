.contact-us-title {
  margin: 1em;
  margin-top: 80px;
  font-size: 2em;
  /* color: rgb(255, 83, 39); */
  color: rgb(255, 252, 223);
}

.contact-us-main {
  max-width: 1500px;
  margin: 0 auto;
  color: rgb(255, 252, 223);
}

.contact-us-main > p {
  font-size: 1.2em;
}

.contact-us-main > div > p {
  font-size: 1.2em;
}

.contact-us-main > div > a {
  font-size: 1.2em;
  color: rgb(255, 252, 223);
}

.contact-us-main > div {
  margin: 4em;
}

.contact-us-main > div:last-child {
  margin: 0em;
}

.contact-us-button {
  color: white;
  background-color: #ee661b;
  border: 0px;
  padding: 1em;
  font-size: 1.2em;
  border-radius: 5px;
}

.contact-icon {
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 5px;
  font-size: 1.5em;
}

.contact-icon-container {
  font-size: 2em;
  margin: 0em;
  padding: 0em;
}

.contact-icon-container > a {
  margin-top: 0em;
  margin-bottom: 0em;
  width: 60px;
  height: 60px;
  padding: 0.2em;
  padding-top: 2px;
  padding-bottom: 2px;
}
