.menu-section-title {
  color: rgb(255, 83, 39);
  /* border-top: 1px solid #ddd; */
  border-bottom: 1px solid #ddd;
  padding: 1em;
}

.menu-section-subtitle {
  font-size: 1em;
}

.menu-section-footer {
  font-size: 1em;
  margin-bottom: 3em;
}
