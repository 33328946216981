.about-us-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 3em;
}

.about-us-image-container > div {
  /* width: 100%; */
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-image-container > div:first-child {
  transform: rotate(-3deg);
}
.about-us-image-container > div:last-child {
  transform: rotate(3deg);
}

@media only screen and (max-width: 750px) {
  .about-us-image-container {
    grid-template-columns: 1fr 1fr;
  }
  .about-us-image:first-child {
    grid-row: 2;
  }

  .about-us-image:nth-child(2n) {
    grid-column: span 2;
    margin: 0 auto;
  }

  .about-us-image-container > div:last-child {
    grid-column: span 2;
  }

  .about-us-image-container > div:nth-child(2n) {
    transform: rotate(3deg);
  }
  .about-us-image-container > div:last-child {
    transform: rotate(0deg);
  }
}

.about-us-image {
  height: 350px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  color: white;
  font-size: 1.2em;
  font-weight: 600;
}

.about-us-image:first-child {
  background-position: 50% 20%;
}

.about-us-image-container > div:last-child > div > div {
  background-position: 50% 10%;
}

.about-us-image > div {
  height: 100%;
  width: 100%;
  background-color: rgba(12, 12, 12, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-title {
  margin: 1em;
  margin-top: 0em;
  padding-top: 1em;
  /* margin-top: 80px; */
  font-size: 2em;
  /* color: rgb(255, 83, 39); */
  color: rgb(255, 252, 223);
}

hr {
  width: 70px;
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

.about-us-main {
  max-width: 1500px;
  margin: 0em auto;
  padding: 1em;
  background-color: #cd212a;
  color: rgb(255, 252, 223);
  font-size: 1.1em;
}
