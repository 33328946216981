.header-main {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2em;
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 0.3em;
  padding-right: 0.3em;
  color: rgb(255, 83, 39);
  color: rgb(255, 252, 223);
  background-color: #cd212a;
  padding-top: 40px;
}

.header-icon {
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 5px;
}

.hide-when-small {
}

@media only screen and (max-width: 550px) {
  .hide-when-small {
    display: none;
  }
}
