.alert-bar-main {
  position: fixed;
  background-color: #008c45;
  width: 100%;
  margin-top: 0em;
  color: white;
  padding: 5px;
  font-size: 1.2em;
  z-index: 100;
  /* border-bottom: 5px solid; */
}

@media only screen and (max-width: 550px) {
  .alert-bar-main {
    font-size: 14px;
  }
}
