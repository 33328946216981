.menu-item-main {
  display: flex;
  justify-content: space-between;
}

.menu-item-main:last-child {
  text-align: right;
}

.menu-item-name {
  padding-left: 0.3em;
  text-align: left;
}

.menu-item-price {
  padding-right: 0.3em;
}
