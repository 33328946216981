.menu-main {
  max-width: 1500px;
  margin: 0 auto;
  border: 1px solid #ddd;
  margin-top: 3em;
  padding: 1em;
  padding-bottom: 3em;
  background-color: white;
  width: 90%;
  -webkit-box-shadow: 2px 2px 5px 0px #802a2a;
  -moz-box-shadow: 2px 2px 5px 0px #802a2a;
  box-shadow: 2px 2px 5px 0px #802a2a;
}

.menu-title {
  margin: 3em 0em;
  border: 1px solid #ddd;
  padding: 0.7em 1.5em;
  margin-bottom: 1em;
  cursor: pointer;
  color: rgb(255, 83, 39);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.active-menu {
  background-color: #008c45;
  color: white;
}

.menu-title-container {
  display: flex;
  justify-content: center;
}

.menu-title-container > h2:last-child {
  border-left: 0px;
}

.menu-nav {
  display: grid;
  /* justify-content: space-evenly; */
  max-width: 1000px;
  grid-template-columns: repeat(6, 1fr);
  border: 1px solid #ddd;
  border-bottom: 0px;
  margin: 2em auto;
  cursor: pointer;

  /* margin-top: 1em; */
}

.dinner-menu {
  grid-template-columns: repeat(3, 1fr);
}

.menu-nav > a {
  border-right: 1px solid #ddd;
  margin: 0em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.menu-nav > a:visited {
  color: black;
}

@media only screen and (min-width: 1000px) {
  .menu-nav > a:nth-child(6n) {
    border-right: 0px;
  }

  .dinner-menu {
    grid-template-columns: repeat(3, 1fr);
  }
}

.menu-nav > a:hover {
  background-color: #008c45;
  color: white;
}

.menu-nav > a:last-child {
  border-right: 0px;
}

@media only screen and (max-width: 1000px) {
  .menu-nav {
    grid-template-columns: repeat(4, 1fr);
  }

  .dinner-menu {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 650px) {
  .menu-nav {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 450px) {
  .menu-nav {
    grid-template-columns: repeat(2, 1fr);
  }

  .dinner-menu {
    border-bottom: 1px solid #ddd;
  }

  .dinner-menu > a:last-child {
    border-right: 1px solid #ddd;
    border-bottom: 0px;
  }
}

.menu-body {
  max-width: 1000px;
  margin: 0 auto;
}
