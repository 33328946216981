.hero-banner-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  border-bottom: 5px solid #008c45;
  border-top: 5px solid #008c45;
}

.hero-banner-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-style: normal;
  font-weight: bold;
  color: #eee;
  font-size: 2vmin;
  letter-spacing: 0.03em;
  line-height: 1;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);
  /* margin-bottom: 40px; */
}

.order-online-button {
  margin-top: 16px;
  margin-bottom: 36px;
}

@media only screen and (max-width: 550px) {
  .hero-banner-main {
    grid-template-columns: 1fr;
    height: 500px;
  }

  .hero-banner-image {
    height: 200px;
  }

  .hero-banner-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3vmin;
  }

  /* .hero-banner-main > div:last-child {
    font-size: 0.9em;
  } */
}

.hero-banner-main > div {
  text-align: center;
}
/* 
.hero-banner-main > div:last-child {
  background-color: rgb(255, 83, 39);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
} */

.hero-banner-image {
  height: 500px;
  /* background-size: cover; */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  /* background-size: cover; */
  width: 100%;
}
/* 
.hero-banner-text {
  padding: 2em;
  box-sizing: border-box;
} */

.hero-banner-text > a {
  font-size: 1.2em;
  color: white;
  text-decoration: none;
  cursor: pointer;
  background-color: #008c45;
  padding: 0.7em 1em;
  border-radius: 5px;
}
